import React from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";
import { Grid, Typography, Box } from "@mui/material";
import Footer from "./Footer";
import { HOMEDATA } from "./PDFDATA/pdfListData";

const Dashboard = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#d0d0d0 !important",
              border: "#d0d0d0",
            },
          "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
            color: "#7c7c7c !important",
          },
        }}
      >
        {/* Navbar */}
        <Grid item xs={12}>
          <Navbar />
        </Grid>

        {/* Banner */}
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 768px)": {
              margin: "30px 0px 15px 0px",
            },
            margin: "25px 0px",
          }}
        >
          <Banner />
        </Grid>

        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            style={{ width: "96%" }}
            display={"flex"}
            flexDirection={"column"}
          >
            {HOMEDATA?.map((item, index) => {
              return index === 0 ? (
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "4rem",
                    marginBottom: "30px",
                  }}
                >
                  {item?.title ?? item?.subtext}
                </Typography>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "left",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                      marginBottom: "8px",
                      lineHeight: 1.8,
                    }}
                  >
                    {index}.&nbsp;
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "justify",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                      marginBottom: "8px",
                      lineHeight: 1.8,
                    }}
                  >
                    {item?.title ?? item?.subtext}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
