import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import Link from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <>
      <Grid
        container
        sx={{
          background:
            "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
          width: "100%",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "45vw",
            display: "flex",
            alignItems: "center",
            fontSize: "17px",
            justifyContent: "space-around",
            "@media screen and (max-width: 768px)": {
              width: "100vw",
              fontSize: "14px",
              marginTop: "1rem",
              alignItems: "center",
              justifyContent: "space-evenly",
            },
          }}
        >
          <Box sx={{}}>
            <Link href="/dashboard" underline="none" color="inherit">
              Home
            </Link>
          </Box>

          <Box sx={{}}>
            <Link href="/aboutus" underline="none" color="inherit">
              About us
            </Link>
          </Box>
          <Box sx={{}}>
            <Link href="/pushkaranasandesh" underline="none" color="inherit">
              Pushkarana Sandesh
            </Link>
          </Box>
          <Box sx={{}}>
            <Link href="/contactus" underline="none" color="inherit">
              Contact Us
            </Link>
          </Box>
          <Box sx={{}}>
            <Link href="/correspondence" underline="none" color="inherit">
              Correspondence
            </Link>
          </Box>
        </Box>

        <Box
          sx={{
            paddingRight: "1.3rem",

            "@media screen and (max-width: 768px)": {
              width: "100vw",
              marginTop: "1rem",
              marginBottom: "1rem",
              padding: "0 0.6rem",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Typography margin="0.5rem 0" style={{ fontWeight: "500" }}>
          Join Mailing List
          </Typography>
          <TextField
            variant="outlined"
            // fullWidth
            sx={{
              // marginBottom: 16,
              color: "#fff",
              "& fieldset": { border: "none" },
              border: " 1px solid #fff",
              borderRadius: "10px",
              "&:hover": {
                border: " 1px solid #fff",
              },
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <Button
                      variant="contained"
                      sx={{
                        fontWeight: "400",
                        padding: "10px 12px",
                        marginRight: "-8px",
                        marginBottom: "2px",
                        borderRadius: "10px",
                        background: "rgb(251,151,63) !important",
                        "&:hover": {
                          backgroundColor: "rgb(251,151,63) !important",
                          color: "#000",
                        },
                      }}
                    >
                      Subscribe Now
                    </Button>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>

      <Grid
        container
        sx={{
          paddingTop: "1rem",
          justifyContent: "center",
          background:
            "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
        }}
      >
        <Box marginBottom="1rem">
          <Link href="#" underline="none" color="#fff">
            <FacebookIcon />
          </Link>
          <Link href="#" underline="hover" color="#fff">
            <TwitterIcon />
          </Link>
          <Link href="#" underline="always" color="#fff">
            <InstagramIcon />
          </Link>
        </Box>
      </Grid>

      <Grid
        container
        sx={{
          justifyContent: "center",
          padding: 0,
          color: "white",
          background:
            "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
        }}
      >
        <Link
          color="inherit"
          sx={{ textDecoration: "none" }}
          marginBottom="0.5rem"
        >
          Powered By Mobiloitte.
        </Link>
      </Grid>
    </>
  );
};

export default Footer;

//------------------------------------------------------------------------------------------------------------

// import {
//   Grid,
//   Typography,
//   Input,
//   Button,
//   InputAdornment,
//   Box,
//   TextField,
//   IconButton,
// } from "@mui/material";
// import CssBaseline from "@mui/material/CssBaseline";
// import Link from "@mui/material/Link";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";

// // const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

// const Footer = () => {
//   return (
//     <>
//       <Grid
//         container
//         spacing={2}
//         sx={{
//           background: "rgb(251,151,63)",
//           background:
//             "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
//           padding: "0px",
//           display: "flex",
//           width: "100%",
//           color: "white",
//           margin: "0px",
//         }}
//       >
//         <Grid item xs={12} lg={3} md={6} sx={{ padding: "10px" }}>
//           <Grid container direction="column" s>
//             <Grid item color="#000">
//               Pushkarana
//             </Grid>
//             <Grid item sx={{ marginRight: { xs: "1rem", sm: "0" } }}>
//               <Typography sx={{ marginTop: "2rem", textAlign: "justify" }}>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                 eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
//               </Typography>
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item xs={12} lg={3} md={6}>
//           <Box sx={{ paddingLeft: { xl: "4rem", xs: "0rem" } }}>
//             <Box sx={{ marginBottom: "0.7rem" }}>
//               <Link href="#home" underline="none" color="inherit">
//                 Home
//               </Link>
//             </Box>

//             <Box sx={{ marginBottom: "0.7rem" }}>
//               <Link href="#about" underline="none" color="inherit">
//                 About us
//               </Link>
//             </Box>
//             <Box sx={{ marginBottom: "0.7rem" }}>
//               <Link href="#gallery" underline="none" color="inherit">
//                 Gallery
//               </Link>
//             </Box>
//             <Box sx={{ marginBottom: "0.7rem" }}>
//               <Link href="#contact" underline="none" color="inherit">
//                 Contact Us
//               </Link>
//             </Box>
//             <Box sx={{ marginBottom: "0.7rem" }}>
//               <Link href="#newsletter" underline="none" color="inherit">
//                 Newsletter
//               </Link>
//             </Box>
//           </Box>
//         </Grid>

//         <Grid item xs={12} lg={3} md={6} sx={{ padding: "10px" }}>
//           <Box sx={{ marginBottom: "0.7rem" }}>
//             <Link href="#" underline="none" color="inherit">
//               Term and Conditions
//             </Link>
//           </Box>
//           <Box sx={{ marginBottom: "0.7rem" }}>
//             <Link href="#" underline="none" color="inherit">
//               Privacy Policy
//             </Link>
//           </Box>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           lg={3}
//           md={6}
//           sx={{ marginRight: { xs: "1rem", sm: "0" }, padding: "10px" }}
//         >
//           <Typography marginBottom="1.5rem">Subscribe to Newsletter</Typography>
//           <TextField
//             variant="outlined"
//             fullWidth
//             sx={{
//               marginBottom: 16,
//               color: "#fff",
//               "& fieldset": { border: "none" },
//               border: " 1px solid #fff",
//               borderRadius: "10px",
//               "&:hover": {
//                 border: " 1px solid #fff",
//               },
//             }} // Set the height of the TextField
//             InputProps={{
//               disableUnderline: true,
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton edge="end">
//                     <Button
//                       variant="contained"
//                       sx={{
//                         padding: "13px 14px",
//                         marginRight: "-8px",
//                         marginBottom:"2px",
//                         borderRadius: "10px",
//                         background: "rgb(251,151,63)",
//                         "&:hover": {
//                           backgroundColor: "rgb(251,151,63) !important",
//                           color: "#000",
//                         },
//                       }}
//                     >
//                       Subscribe Now
//                     </Button>
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         </Grid>
//       </Grid>

//       <Grid
//         container
//         sx={{
//           justifyContent: "center",
//           background: "rgb(251,151,63)",
//           background:
//             "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
//         }}
//       >
//         <Box marginBottom="1rem">
//           <Link href="#" underline="none" color="#fff">
//             <FacebookIcon />
//           </Link>
//           <Link href="#" underline="hover" color="#fff">
//             <TwitterIcon />
//           </Link>
//           <Link href="#" underline="always" color="#fff">
//             <InstagramIcon />
//           </Link>
//         </Box>
//       </Grid>

//       <Grid
//         container
//         sx={{
//           justifyContent: "center",
//           padding: 0,
//           color: "white",
//           background: "rgb(251,151,63)",
//           background:
//             "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
//         }}
//       >
//         <Link
//           color="inherit"
//           sx={{ textDecoration: "none" }}
//           marginBottom="0.5rem"
//         >
//           Mobiloitte 2023. All Rights Reserved
//         </Link>
//       </Grid>
//     </>
//   );
// };

// export default Footer;
