import React, { useState, useMemo } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";

import { pdfList } from "./PDFDATA/pdfListData";
import Basic from "./Basic/Basic";
import Banner from "./Banner";
// import Aestronote from "../Assets/Ganesha-beginnings-Hindu.webp"; // Import your contact image

const Pushkarana = () => {
  const [pdfPath, setPdfPath] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openPdfView = (path) => {
    setPdfPath(
      // `https://intranet.mobiloittegroup.com/uploads/estimations/uploads/${path}`
      `https://docs.google.com/gview?url=https://intranet.mobiloittegroup.com/uploads/estimations/uploads/${path}&embedded=true`
    );
  };
  // const openPdfView = (path) => {
  //   window.open(
  //     `https://intranet.mobiloittegroup.com/uploads/estimations/uploads/${path}`,
  //     "_blank"
  //   );
  // };

  const handleClearPdf = () => {
    setPdfPath(null);
  };

  const filteredPdfList = useMemo(() => {
    return pdfList;
  }, []);
  return (
    <Basic>
      <Box display={pdfPath ? "none" : "block"}>
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              // display: { xs: "none", sm: "block" },
              marginTop: { xs: "4rem" },
            }}
          >
            <Banner />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              textAlign="center"
              marginBottom="20px"
              variant="h4"
              sx={{
                fontFamily: "Libre Baskerville,serif",
                fontWeight: 600,
                fontSize: "50px",
                color: "rgb(251,151,63)",
                marginTop: "1rem",
              }}
            >
              Pushkarana Sandesh
            </Typography>
          </Grid>
          {/*  <Grid item xs={12} sm={6} md={3} p={2}>
            <Typography
              variant="h4"
              sx={{
                marginTop: "10px",
                fontSize: {
                  xs: "1.1rem",
                  sm: "2rem",
                  md: "2.2rem",
                  lg: "2.4rem",
                  paddingLeft: "20px",
                },
              }}
            >
              List of Pdf
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            p={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-end", md: "center" },
              paddingRight: { xs: "20px" },
              paddingLeft: { xs: "20px" },
            }}
          >
            <TextField
              // fullWidth
              size={matches ? "small" : "medium"}
              placeholder="Search by name or date."
              value={searchQuery}
              onChange={handleSearch}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            p={2}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "center" },
              paddingLeft: { xs: "20px" },
              paddingRight: { xs: "20px" },
              "& .MuiTextField-root": {
                width: "100%",
              },
              "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#d0d0d0 !important",
                },
              "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                color: "#7c7c7c !important",
              },
            }}
          >
            <DatePicker
              label="From Date"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d0d0d0 !important",
                },
              }}
              onError={(error, value) => value === null}
              value={fromDate ?? "MM/DD/YYYY"}
              onChange={(newValue) => handleFromDateChange(newValue)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            p={2}
            md={3}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-end", md: "center" },
              paddingRight: { xs: "20px" },
              paddingLeft: { xs: "20px" },
              "& .MuiTextField-root": {
                width: "100%",
              },
              "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#d0d0d0 !important",
                },
              "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                color: "#7c7c7c !important",
              },
            }}
          >
            <DatePicker
              label="To Date"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d0d0d0 !important",
                },
              }}
              onError={(error, value) => value === null}
              value={toDate ?? "MM/DD/YYYY"}
              onChange={(newValue) => handleToDateChange(newValue)}
            />
          </Grid> */}
        </Grid>
        <Grid container>
          <Grid item lg={12} container>
            {filteredPdfList.map((pdf, index) => (
              <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xl={3}
                xs={12}
                key={pdf.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    flex: "1 1 0%",
                    flexDirection: "column",
                    cursor: "pointer",
                    boxShadow:
                      isHovered === index
                        ? "2px 3px 11px 4px rgba(248, 237, 198, 0.86)"
                        : "none",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    transform: isHovered === index ? "scale(1.1)" : "scale(1)",
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Typography sx={{ flex: 1, textAlign: "center" }}>
                    {pdf.name}
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "#FF8C00",
                      color: "#000",
                      marginTop: "10px",
                      "&:hover": { backgroundColor: "#FF8C00" },
                    }}
                    variant="contained"
                    onClick={() => openPdfView(pdf.path)}
                  >
                    Open PDF
                  </Button>
                </Box>
              </Grid>
            ))}
            {filteredPdfList?.length === 0 && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  No Data Found.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {pdfPath && (
        <Box sx={{ marginTop: "70px" }}>
          <Button sx={{ margin: "5px 0" }} onClick={handleClearPdf}>
            Back to Pushkarana Sandesh List
          </Button>
          <iframe
            src={pdfPath}
            title="PDF Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        </Box>
      )}
    </Basic>
  );
};

export default Pushkarana;

// const Gallery = () => {
//   const underlineTransition = {
//     position: "relative",
//     "&::after": {
//       content: '""',
//       position: "absolute",
//       bottom: 0,
//       left: 0,
//       width: "5%",
//       height: "2px",
//       backgroundColor: "rgb(251,151,63)", // Change color as needed
//       transition: "width 0.3s ease-in-out", // Transition effect
//     },
//     "&:hover::after": {
//       width: "17%", // Expand underline on hover
//     },
//   };
//   return (
//     <>
//       <Grid container>
//         <Grid item xs={12}>
//           <Typography
//             textAlign="center"
//             marginBottom="20px"
//             variant="h4"
//             sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5" ,marginTop:{xs:"3rem"} }}
//           >
//             Gallery
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Typography
//             variant="h4"
//             sx={{
//               fontFamily: "Libre Baskerville,serif",
//               marginLeft: "1rem",
//               marginTop: "2rem",
//               ...underlineTransition,
//             }}
//           >
//             Hindiusm
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           sx={{
//             order: { xs: 2, md: 1 },
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Box sx={{ width: "85%", height: "95%" ,boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.9)", borderRadius: "0.5rem"}}>
//             <img
//               src={Aestronote}
//               alt="Aestronote"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 borderRadius: "0.5rem",
//               }}
//             />
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default Gallery;
