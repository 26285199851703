import "./App.css";
import Routing from "./Routes/Routes";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function App() {
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Routing />
      </Box>
    </LocalizationProvider>
  );
}

export default App;
