import React from "react";
import { Typography, Grid } from "@mui/material";
import Basic from "./Basic/Basic";
import Card from "@mui/material/Card";
import Banner from "./Banner";
import basanti from "../Assets/ContactImages/basanti.jpeg";
import harsh from "../Assets/ContactImages/harsh.jpeg";
import champalal from "../Assets/ContactImages/champalal.jpeg";
import trilok from "../Assets/ContactImages/trilok.jpeg";
import kailash from "../Assets/ContactImages/kailash.jpeg";
import mahesh from "../Assets/ContactImages/mahesh.jpeg";
import poonam from "../Assets/ContactImages/poonam.jpeg";
import gopal from "../Assets/ContactImages/gopal.jpeg";
import ajay from "../Assets/ContactImages/ajay.jpeg";
import sanjay from "../Assets/ContactImages/sanjay.jpeg";
import purshotam from "../Assets/ContactImages/purshotam.jpeg";
// import { Formik } from "formik";

const ContactForm = () => {
  const styles = {
    designation: {
      fontSize: "20px",
      fontWeight: 500,
    },
    name: {
      fontSize: "25px",
      fontWeight: 600,
    },
    address: {
      fontSize: "15px",
      fontWeight: 500,
    },
    email: {
      fontSize: "18px",
      fontWeight: 500,
    },
    mobile: {
      fontSize: "20px",
      fontWeight: 500,
    },
    cardSize: {
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      // cursor: "pointer",
      "&:hover": {
        boxShadow: "2px 3px 11px 4px rgba(248, 237, 198, 0.86)",
        transform: "scale(1.1)",
      },
    },
    gridStyle: {
      display: "flex",
      justifyContent: "center",
    },
    imgSize: {
      height: "200px",
      width: "200px",
      borderRadius: "50%",
      margin: "0 auto",
      marginBottom: "20px",
      marginTop: "10px",
    },
  };
  return (
    <Basic>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            // display: { xs: "none", sm: "block" },
            marginTop: { xs: "4rem" },
          }}
        >
          <Banner />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
            }}
          >
            Contact Us
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            पदाधिकारी
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={4} sx={styles.gridStyle}> */}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>संरक्षक</Typography>
            <Typography sx={styles.name}>श्री राधेश्याम रंगा</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 98290-19050</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>संरक्षक</Typography>
            <Typography sx={styles.name}>श्री एस. एस. बिस्सा</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94141-50077</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>संरक्षक</Typography>
            <Typography sx={styles.name}>श्री अमरचंद पुरोहित</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 93147-00816</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>राष्ट्रीय अध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री प्रकाश छंगाणी</Typography>
            <Typography sx={styles.address}>
              छंगाणी भवन, स्टेशन रोड़ फलौदी (राज.)
            </Typography>
            <Typography sx={styles.email}>
              ई-मेल : chhangani@gmail.com
            </Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94141-30310</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>वरिष्ठ उपाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री महेश व्यास</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 98282-26800</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={gopal} alt="gopal" />
            <Typography sx={styles.designation}>उपाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्रीगोपाल कूड़ पुरोहित</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 87699-19865</Typography>
            {/* <Typography sx={styles.mobile}>
              मोबाइल : 83293-48825 , 93717-48971
            </Typography> */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={champalal} alt="champalal" />
            <Typography sx={styles.designation}>उपाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री चम्पालाल पुरोहित</Typography>
            <Typography sx={styles.address}>चैन्नई</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 93818-55333</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={kailash} alt="kailash" />
            <Typography sx={styles.designation}>उपाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री कैलाश पुरोहित </Typography>
            <Typography sx={styles.address}>दिल्ली</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 98186-30789</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={poonam} alt="poonam" />
            <Typography sx={styles.designation}>उपाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री पूनम रंगा</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 98315-27084</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={harsh} alt="harsh" />
            <Typography sx={styles.designation}>महामंत्री</Typography>
            <Typography sx={styles.name}>डॉ. एस.एन. हर्ष</Typography>
            <Typography sx={styles.email}>
              ई-मेल : drsnharsh@gmail.com
            </Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94141-40004</Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={ajay} alt="ajay" />
            <Typography sx={styles.designation}>मंत्री</Typography>
            <Typography sx={styles.name}>श्री अजय जोशी</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 99984-20999</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={sanjay} alt="sanjay" />
            <Typography sx={styles.designation}>मंत्री</Typography>
            <Typography sx={styles.name}>श्री संजय जोशी</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94221-90355</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>मंत्री</Typography>
            <Typography sx={styles.name}>श्री प्रदीप व्यास</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>मंत्री</Typography>
            <Typography sx={styles.name}>श्री प्रकाश व्यास</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 99280-76651</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>मंत्री</Typography>
            <Typography sx={styles.name}>श्री विनोद व्यास</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94148-61473</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={mahesh} alt="mahesh" />
            <Typography sx={styles.designation}>कोषाध्यक्ष</Typography>
            <Typography sx={styles.name}>श्री महेश आचार्य</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 80940-02936</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={purshotam} alt="purshotam" />
            <Typography sx={styles.designation}>
              निजी सलाहकार अध्यक्ष
            </Typography>
            <Typography sx={styles.name}>श्री पुरुषोत्तम मापारा</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94148-49849</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>
              मीडिया सलाहकार अध्यक्ष
            </Typography>
            <Typography sx={styles.name}>श्री उदय पुरोहित</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 96104-19000</Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={trilok} alt="trilok" />
            <Typography sx={styles.designation}>
              राष्ट्रीय अध्यक्ष (युवा प्रकोष्ठ){" "}
            </Typography>
            <Typography sx={styles.name}>
              श्री त्रिलोक नारायण पुरोहित
            </Typography>
            <Typography sx={styles.mobile}>मोबाइल : 86192-05872</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img style={styles.imgSize} src={basanti} alt="basanti" />
            <Typography sx={styles.designation}>
              राष्ट्रीय अध्यक्ष (महिला प्रकोष्ठ)
            </Typography>
            <Typography sx={styles.name}>डॉ. बसंती हर्ष</Typography>
            <Typography sx={styles.mobile}>मोबाइल : 94606-16118</Typography>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>सचिव</Typography>
            <Typography sx={styles.name}>
              श्री संजय झुम्बरलालजी छंगाणी
            </Typography>

            <Typography sx={styles.mobile}>मोबाइल : 94221-90355</Typography>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <Typography sx={styles.designation}>सचिव</Typography>
            <Typography sx={styles.name}>श्री भुज कच्छ</Typography>

            <Typography sx={styles.mobile}>मोबाइल : 99984-20999</Typography>
          </Card>
        </Grid> */}

        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
            }}
          >
            Image
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ padding: "1rem" }}>
            <Typography variant="h4">Contact Form</Typography>
            <Formik
              initialValues={{
                name: "",
                email: "",
                contactNumber: "",
                reason: "",
                description: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.contactNumber) {
                  errors.contactNumber = "Required";
                }
                if (!values.reason) {
                  errors.reason = "Required";
                }
                if (!values.description) {
                  errors.description = "Required";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name && errors.name}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && errors.email}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Contact Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="contactNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactNumber}
                    error={errors.contactNumber && touched.contactNumber}
                    helperText={
                      errors.contactNumber &&
                      touched.contactNumber &&
                      errors.contactNumber
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Reason"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="reason"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reason}
                    error={errors.reason && touched.reason}
                    helperText={
                      errors.reason && touched.reason && errors.reason
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    margin="normal"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    error={errors.description && touched.description}
                    helperText={
                      errors.description &&
                      touched.description &&
                      errors.description
                    }
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    style={{ marginTop: "1rem",background:"rgb(251,151,63)" }}
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        </Grid> */}

        {/* </Grid> */}
      </Grid>
    </Basic>
  );
};

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (field) => (event) => {
//     setFormData({
//       ...formData,
//       [field]: event.target.value,
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Add your logic to handle form submission here
//     console.log("Form submitted:", formData);
//   };
//   const mapContainerStyle = {
//     width: "100%",
//     height: "100%",
//   };

//   const center = {
//     lat: 37.7749, // Replace with the desired latitude
//     lng: -122.4194, // Replace with the desired longitude
//   };

//   return (
//     <Grid container marginBottom="10px" spacing={0}>
//       <Grid item xs={12}>
//         <Typography
//           textAlign="center"
//           marginBottom="20px"
//           variant="h4"
//           sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5",marginTop:{xs:"3rem"}}}
//         >
//           Contact Us
//         </Typography>
//       </Grid>
//       <Grid item xs={12} md={6}>
//         <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
//           <GoogleMap
//             mapContainerStyle={mapContainerStyle}
//             center={center}
//             zoom={14}
//           >
//             <Marker position={center} />
//           </GoogleMap>
//         </LoadScript>
//       </Grid>

//       <Grid item xs={12} md={6}>
//         <Typography variant="body1">{/* Your text goes here */}</Typography>
//         <form onSubmit={handleSubmit}>
//           <Box sx={{ padding: "1rem" }}>
//             <TextField
//               label="Name"
//               fullWidth
//               margin="normal"
//               value={formData.name}
//               onChange={handleChange("name")}
//               required
//             />
//             <TextField
//               label="Mobile No."
//               fullWidth
//               margin="normal"
//               value={formData.mobile}
//               onChange={handleChange("mobile")}
//               required
//             />
//             <TextField
//               label="Email ID"
//               fullWidth
//               margin="normal"
//               type="email"
//               value={formData.email}
//               onChange={handleChange("email")}
//               required
//             />
//             <TextField
//               label="Message"
//               fullWidth
//               margin="normal"
//               multiline
//               rows={4}
//               value={formData.message}
//               onChange={handleChange("message")}
//               required
//             />
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 marginTop: "1rem",
//               }}
//             >
//               <Button type="submit" variant="contained"  sx={{
//                 background: "rgb(251,151,63)",
//                  color: "#000",
//                  "&:hover":{
//                  backgroundColor:"#fff !important" ,
//                  color:"rgb(251,151,63)"
//                  }
//                }}>
//                 Submit
//               </Button>
//             </Box>
//           </Box>
//         </form>
//       </Grid>
//     </Grid>
//   );
// };

export default ContactForm;
