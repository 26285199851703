import { Box } from "@mui/material";
import React from "react";
import Banner1 from "../Assets/Banner2.png";
const Banner = () => {
  return (
    <Box style={{ backgroundColor: "#f4e533" }}>
      <img src={Banner1} alt="TaskManager" style={{ width: "100%" }} />
    </Box>
  );
};

export default Banner;
