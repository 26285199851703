import React from "react";
import Basic from "./Basic/Basic";
import { Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Banner from "./Banner";
import gallery1 from "../Assets/GalleryImages/gallery1.jpeg";
import gallery2 from "../Assets/GalleryImages/gallery2.jpeg";
import gallery3 from "../Assets/GalleryImages/gallery3.jpeg";
import gallery4 from "../Assets/GalleryImages/gallery4.jpeg";
import gallery5 from "../Assets/GalleryImages/gallery5.jpeg";
import gallery6 from "../Assets/GalleryImages/gallery6.jpeg";
import gallery7 from "../Assets/GalleryImages/gallery7.jpeg";
import gallery8 from "../Assets/GalleryImages/gallery8.jpeg";
import gallery9 from "../Assets/GalleryImages/gallery9.jpeg";
import gallery10 from "../Assets/GalleryImages/gallery10.jpeg";
import gallery11 from "../Assets/GalleryImages/gallery11.jpeg";
import gallery12 from "../Assets/GalleryImages/gallery12.jpeg";
import gallery13 from "../Assets/GalleryImages/gallery13.jpeg";
import gallery14 from "../Assets/GalleryImages/gallery14.jpeg";
import gallery15 from "../Assets/GalleryImages/gallery15.jpeg";
import gallery16 from "../Assets/GalleryImages/gallery16.jpeg";
import gallery17 from "../Assets/GalleryImages/gallery17.jpeg";
import gallery18 from "../Assets/GalleryImages/gallery18.jpeg";
import gallery19 from "../Assets/GalleryImages/gallery19.jpeg";
import gallery20 from "../Assets/GalleryImages/gallery20.jpeg";
import gallery21 from "../Assets/GalleryImages/gallery21.jpeg";
import gallery22 from "../Assets/GalleryImages/gallery22.jpeg";
import gallery23 from "../Assets/GalleryImages/gallery23.jpeg";
import gallery24 from "../Assets/GalleryImages/gallery24.jpeg";
import gallery25 from "../Assets/GalleryImages/gallery25.jpeg";

const Gallery = () => {
  const styles = {
    cardSize: {
      width: "350px",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "2px 3px 11px 4px rgba(248, 237, 198, 0.86)",
        transform: "scale(1.1)",
      },
    },
    gridStyle: {
      display: "flex",
      justifyContent: "center",
    },
  };
  return (
    <Basic>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            // display: { xs: "none", sm: "block" },
            marginTop: { xs: "4rem" },
          }}
        >
          <Banner />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
            }}
          >
            Gallery
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery1} alt="gallery1" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery2} alt="gallery2" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery3} alt="gallery3" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery4} alt="gallery4" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery5} alt="gallery5" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery6} alt="gallery6" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery7} alt="gallery7" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery8} alt="gallery8" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery9} alt="gallery9" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery10} alt="gallery10" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery11} alt="gallery11" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery12} alt="gallery12" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery13} alt="gallery13" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery14} alt="gallery14" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery15} alt="gallery15" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery16} alt="gallery16" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery17} alt="gallery17" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery18} alt="gallery18" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery19} alt="gallery19" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery20} alt="gallery20" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery21} alt="gallery21" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery22} alt="gallery22" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery23} alt="gallery23" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery24} alt="gallery24" />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} sx={styles.gridStyle}>
          <Card sx={styles.cardSize}>
            <img src={gallery25} alt="gallery25" />
          </Card>
        </Grid>
      </Grid>
    </Basic>
  );
};

export default Gallery;
